<template>
  <f7-page class="notifications notif-title">
    <template #fixed>
      <ProductNavigationComponent :title="$t.getTranslation('LBL_NOTIFICATIONS')" type="back" :search="false" :cart="false" />
    </template>

    <template v-if="NotificationList.length">
      <ul>
        <template v-for="item in NotificationList" :key="item.NotificationId">
          <NotificationNavigationComponent :item="item" class="b-bottom" />
        </template>
      </ul>
    </template>

    <template v-else>
      <NoDataFoundComponent :size="'sm'" :title="$t.getTranslation('LBL_NOTIFICATIONS_EMPTY')" />
    </template>
  </f7-page>
</template>

<script>
import { defineComponent, computed, ref, onMounted, defineAsyncComponent } from "vue";
import { useStore } from "@/store";
import { get } from "@/utils/axios";

// import CommonNavigationComponent from "@/components/navigations/CommonNavigationComponent.vue";
// import NotificationNavigationComponent from "@/components/navigations/NotificationNavigationComponent.vue";
// import NoDataFoundComponent from "@/components/NoDataFoundComponent.vue";

//const CommonNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "common-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/CommonNavigationComponent.vue"));
const NotificationNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "notification-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/NotificationNavigationComponent.vue"));
const NoDataFoundComponent = defineAsyncComponent(() => import(/* webpackChunkName: "no-data" */ /* webpackMode: "lazy" */ "@/components/NoDataFoundComponent.vue"));
const ProductNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "product-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/ProductNavigationComponent.vue"));

export default defineComponent({
  name: "NotificationsPage",
  components: {
    ProductNavigationComponent,
    NoDataFoundComponent,
    NotificationNavigationComponent,
  },
  props: { f7router: Object },
  setup(props) {
    const store = useStore();

    const NotificationList = ref([]);

    const getNotification = async () => {
      let ret = await get("/user/notifications", {});
      NotificationList.value = ret?.List;
    };

    onMounted(() => {
      getNotification();
    });

    return {
      NotificationList,
    };
  },
});
</script>

<style>
.empty-container {
  margin-top: 150px;
}
</style>
<style scoped>
/* .b-bottom{
    border-bottom: 1px solid #ccc;
   }*/
   .notifications ul li {
    padding: 6px 0px;
    position: relative;
    text-align: left;
    display: flex;
    align-items: center;
    margin: 0px 0px 0px;
}

</style>
